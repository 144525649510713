<template>
  <div class="display-protocol">
    <img src="@/assets/protocol-phone-box.png" alt="" />

    <div class="phone-content">
      <p class="protocol-name">{{protocolInfo.name}}</p>
      <div class="protocol-content" v-html="protocolInfo.protocol"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DisplayProtocol",
  components: {},
  props: {
    protocolInfo: Object,
  },

  data() {
    return {};
  },

  computed: {},
  watch: {},
  mounted() {},

  methods: {},
};
</script>
<style lang='less' scoped>
.display-protocol {
  position: absolute;
  top: 0;
  right: 32px;

  .phone-content {
    position: absolute;
    top: 130px;
    right: 44px;
    width: 232px;
    height: 270px;
    overflow-y: auto;

    .protocol-name {
      .text-14();
      .text-bold();
      color: @gray-darker;
      text-align: center;
      padding: 8px 0 16px;
    }

    .protocol-content {
      padding: 0 20px;
    }
  }
}
</style>